.info-section {
    display: grid;
    grid-template-columns: 45fr 55fr;
    width: 100%;
}

.title {
    font-size: 2vw !important;
    height: 100%;
}

.content {
    font-size: 1.25vw !important;
    height: 100%;
}

@media (max-width: 900px) {
    .title {
        font-size: 2.5vw !important;
    }

    .content {
        font-size: 1.8vw !important;
    }

    .info-section {
        display: grid;
        grid-template-columns: 55fr 45fr;
        width: 100%;
    }
}