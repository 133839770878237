.swiper-pagination {
    height: fit-content;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #eff2e9;
    opacity: 0.5;
}

.swiper-pagination-bullet-active {
    background-color: #6BA4DF;
    opacity: 1;
    transform: scale(1.3);
}

.swiper {
    position: relative;
    display: flex;
    flex-direction: column;
}
