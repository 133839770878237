.slick-slider .slick-dots {
    top: 100%;
    height: auto;
    margin-top: 20px;
}

.slick-slider .slick-dots li button:before {
    font-size: 10px;
    color: #6a86a4;
}

.slick-slider .slick-dots li.slick-active button:before {
    color: #6BA4DF;
}

.slick-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slick-slide {
    height: 100%;
    display: flex;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive-slider {
    width: 70% 
}



@media (max-width: 900px) {
    .responsive-carousel {
        padding: 0 4vw !important;
        position: relative;
    }

    .responsive-slider {
        width: 100% !important;
        height: 55vw !important;
    }

    .slick-slider .slick-dots {
        display: none !important;
    }

    .responsive-button {
        /* display: none !important; */
        position: absolute;
        width: 50px;
        height: 50px;
        color: white !important;
        z-index: 2;
    }

    .button-left {
        left: 0;
    }

    .button-right {
        right: 0;
    }
}