#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
  }

  .swiper {
    width: 60%;
    z-index: 0;
  }
  
  .swiper-slide {
    height: 40vh !important;
    z-index: 10;
  }
  
  .swiper-pagination {
    margin-top: 10vh;
  }
  .swiper-pagination-bullet {
    width: 30px;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
  }

  .swiper-slide img {
    display: block;
    object-fit: cover;
  }
  